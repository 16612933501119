import { service } from "@/utils/request";
// import { api } from "@/api/baseurl";
// 获取分销商品列表
export const getDistributorProductList = data => {
    return service({
        method: "get",
        url: "/distributor/product_list",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};

// 获取分销订单信息
export const getDistributorOrderList = data => {
    return service({
        method: "get",
        url: "/distributor/order_list",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};

// 获取提现记录
export const getWithdrawalList = data => {
    return service({
        method: "get",
        url: "/distributor/withdrawal_list",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
// 可提现银行列表
export const getWithdrawableBank = () => {
    return service.get("/distributor/withdrawable_bank").then(result => {
        return result.data;
    });
};
// 上次提现账户信息
export const getLastWithdrawInfo = data => {
    return service({
        method: "get",
        url: "/distributor/get_laster_withdrawal_info",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
// withdrawal
export const withdrawal = data => {
    return service({
        method: "post",
        url: "/distributor/withdrawal",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
// 账户余额
export const getAccountBalance = data => {
    return service({
        method: "get",
        url: "/distributor/account_balance",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
// 删除分销商品
export const deleteProduct = id => {
    return service({
        method: "delete",
        url: "/distributor/delete_product/" + id,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
//分销商等级信息
export const getUserLevel = () => {
    return service({
        method: "get",
        url: "/distributor_grade"
    }).then(res => {
        return res.data;
    });
};
//修改头像
export const updateAvatar = data => {
    return service({
        method: "post",
        url: "/distributor/update_user_info",
        data,
        isToken: 1,
        isCreateOrder: 1
    }).then(res => {
        return res.data;
    });
};
