<template lang="pug">
  .personal-settlement
    .statistics
      .box
        .ttl 
          | 总佣金
          el-tooltip.item(effect='dark', content='所有课程产生收益的总和，包括已提现金额。 ', placement='right')
            img.q.c-pointer(src="@/assets/images/q.png")
        .num
          | ¥
          span.color.ml {{accountInfo.total_commission}}
      .box
        .ttl 
          | 待结算金额
          el-tooltip.item(effect='dark', content='客户当天成功购买相关课程，产生的佣金之和。', placement='right')
            img.q.c-pointer(src="@/assets/images/q.png")
        .num
          | ¥
          span.color.ml.y {{accountInfo.be_settled_amount}}
      .box
        .ttl 
          | 可提现金额
          el-tooltip.item(effect='dark', content='客户购买课程产生收益，对应的佣金在第二天七点之后，可进行提现操作的金额。', placement='right')
            img.q.c-pointer(src="@/assets/images/q.png")
          el-button.primary-btn.withdraw(@click="withdraw") 提现
        .num
          | ¥
          span.color.ml.r {{accountInfo.withdrawable_amount}}
    .settlement-data
      el-table.settlementList-table(
        :data='settlementList'
        style='width: 100%')
        el-table-column(prop='no' label='提现编号' align="center" width='180')
        el-table-column(prop="created_at" label='提现时间' align="center" width='150')
        el-table-column(prop='type_name' label='提现类型' align="center" width='120')
        el-table-column(prop='bank_name' label='开户行' align="center" width='150')
        el-table-column(prop='bank_account' label='银行账号' align="center" width='150')
        el-table-column(label='提现状态'  align="center" width='150')
          template(slot-scope='scope')
            .status(:class="{'red':scope.row.status == '待审核'}") {{scope.row.status}}
        el-table-column(prop='withdrawal_amount' label='提现金额' align="center" width='150')
        el-table-column(prop='tips' label='备注' align="center")
      Pagination(
        :currentPage.sync="pagination.page"
        :pageSize="pagination.perpage"
        :total="pagination.count"
        :sumPage="pagination.page_total")
    withdraw(
      :dialog="withdrawDialog"
      :maxMoney="accountInfo.withdrawable_amount"
      @close="closeCaDialog"
      @openWdSuccess="openWdSuccess")
    wdSuccess(
      :dialog="wdSuccessDialog"
      :wdMoney="wdMoney"
      @close="closeWdSuccessDialog"
    )
</template>
<script>
import withdraw from '@/components-biz/personal/withdraw.vue'
import wdSuccess from '@/components-biz/personal/wdSuccess.vue'
import Pagination from '@/components/pagination'
import {getWithdrawalList} from '@/api/personal.js'
export default {
  props:['active'],
  components:{Pagination,withdraw,wdSuccess},
  data() {
    return {
      settlementList:[],
      pagination:{
        page:1,
        perpage:1,
        count:1,
        page_total:1
      },
      withdrawDialog:false,
      wdSuccessDialog:false,
      wdMoney:null
    }
  },
  watch:{
    active(val){
      if(val == 'settlement'){
        this.getSettlementData()
      }
    },
    'pagination.page'(v){
      this.getSettlementData()
    }
  },
  computed:{
    accountInfo(){
      return this.$store.state.userInfo.accountInfo
    }
  },
  methods:{
    getSettlementData(){
      // 获取结算数据
      // 获取分销商品数据
      const data = {
        page:this.pagination.page
      }
      getWithdrawalList({
        ...data
      }).then(res => {
        if(res.code == 0){
          this.settlementList = res.data.list
          this.pagination = res.data.paginate
        }
      })
    },
    withdraw(){
      // 提现弹窗
      this.withdrawDialog = true
    },
    closeCaDialog(){
      this.withdrawDialog = false
    },
    closeWdSuccessDialog(){
      this.wdSuccessDialog = false
    },
    openWdSuccess(v){
      this.closeCaDialog()
      this.wdSuccessDialog = true
      this.wdMoney = v
    }
  }   
}
</script>

<style lang="less">
.personal-settlement{
  .el-table{
    .status{
      color: #2A67FF;
    }
    .status.red{
      color: #FB3E31;
    }
  }
}
</style>
