<template lang="pug">
el-dialog.l-link-dialog(
    title="分销课程如何获得更大的佣金分成比例"
    width="588px"
    :visible.sync="dialogVisible"
    :before-close="handleClose")
    ul
        li(v-for="(item,index) in level" :key="index")
            img(:src="item.img")
            p(:class="{active : userInfo.grade_type === index + 1}") {{item.text}}
    div.rich(v-html="is_html")
    el-button(type="primary" @click="handleClose") 我知道了
</template>
<script>
export default {
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {};
            }
        },
        dialogVisible: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        html: {
            type: String,
            default: () => {
                return "";
            }
        }
    },
    data() {
        return {
            level: [
                {
                    img: require("../assets/images/level/icon-1.png"),
                    text: "初级分销商"
                },
                {
                    img: require("../assets/images/level/icon-2.png"),
                    text: "中级分销商"
                },
                {
                    img: require("../assets/images/level/icon-3.png"),
                    text: "高级分销商"
                }
            ],
            is_html: ""
        };
    },
    methods: {
        change() {
            this.is_html = this.html;
        },
        handleClose() {
            this.$emit("close");
        }
    },
    watch: {
        html: "change"
    }
};
</script>
<style lang="scss">
.l-link-dialog {
    .el-dialog__header {
        height: 60px;
        line-height: 60px;
        background: linear-gradient(135deg, #517bfc, #517bfc);
        padding: 0 30px;
        .el-dialog__headerbtn {
            border-color: transparent #7c9cfd transparent transparent;
        }
        .el-dialog__title {
            font-size: 18px;
            font-weight: 500 !important;
            display: block !important;
            height: 100%;
            line-height: 60px;
            text-align: center;
        }
    }
    .el-dialog__body {
        padding: 40px;
        background: #fff;
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            li {
                img {
                    width: 130px;
                    height: 130px;
                    display: block;
                }
                p {
                    text-align: center;
                    color: #6b6b6b;
                    font-size: 14px;
                }
                .active {
                    width: 84px;
                    height: 20px;
                    background: #eb3d1f;
                    border: 1px solid;
                    border-color: linear-gradient(
                            270deg,
                            #f8bb46,
                            #ffe69f 43%,
                            #d5a888 72%,
                            #d1814a 4%
                        )
                        1 1;
                    border-radius: 10px;
                    color: #fff;
                    line-height: 20px;
                    font-size: 12px;
                    margin: auto;
                }
            }
        }
        .rich {
            margin-bottom: 20px;
        }
        button {
            width: 180px;
            height: 40px;
            display: block;
            margin: auto;
        }
    }
}
</style>
