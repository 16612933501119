<template lang="pug">
.personal-page
  .pos-fixed
    top-bar
  .pt-top-height
    .content
      h1.logo
        img(src="@/assets/images/logo-center.png")
      .account-center(v-if="userInfo")
        .personal-info
          el-upload.user-avatar(
            class="avatar-uploader"
            action=`${process.env.VUE_APP_BASE_API}/upload`
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload")
            img(
                class="avatar"
                :src="userInfo.avatar ? userInfo.avatar : require('@/assets/images/default-avatar.jpg')")
            .mask 编辑
            //- i(v-else class="el-icon-plus avatar-uploader-icon") 
          .user-info
            div.p(v-show="!editNickName")
              span.user-name {{userInfo.nickname}}
              .edit.c-pointer(@click="changeNickname")
                img(src="@/assets/images/edit.png")
                //- span 编辑
            div.p(v-show="editNickName")
              el-input(id="ncInput" v-model='nickname' maxlength="10" @blur="blurEvent")
              el-button.primary-btn.confirm-button(ref="confirmButton" @click="confirmChangeNickname") 确认
            .level
                img(:src="userInfo.grade_type === 1 ? require('../assets/images/level/level-primary.png'): userInfo.grade_type === 2 ? require('../assets/images/level/level-intermediate.png') : require('../assets/images/level/level-senior.png')")
                p(@click="handleRule") 规则
            span.user-mobile 账号：{{userInfo.mobile}}
        //- el-button.logout(@click="toLogout") 退出登录
      el-tabs.p-tabs(v-model="active")
        el-tab-pane(label="分销商品" name="goods")
          personal-goods(:active="active")
        el-tab-pane(label="订单信息" name="orders")
          personal-orders(:active="active")
        el-tab-pane(label="结算管理" name="settlement")
          personal-settlement(:active="active")
  bottomFoot
  level-popup(:userInfo="userInfo" :dialogVisible="ruleShow" @close="ruleShow = false" :html="html")
</template>
<script>
import levelPopup from "@/components-biz/level-popup.vue";
import personalGoods from "@/components-biz/personal/goods.vue";
import personalOrders from "@/components-biz/personal/orders.vue";
import personalSettlement from "@/components-biz/personal/settlement.vue";
import { updateNickname } from "@/api/login";
import {
    getAccountBalance,
    getUserLevel,
    updateAvatar
} from "@/api/personal.js";
import { setTimeout } from "timers";
export default {
    components: {
        personalGoods,
        personalOrders,
        personalSettlement,
        levelPopup
    },
    data() {
        return {
            editNickName: false,
            nickname: "",
            active: "",
            ruleShow: false,
            html: ""
        };
    },
    mounted() {
        this.active = "goods";
        this.getAccountBalance();
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo.userInfo;
        }
    },
    watch: {
        editNickName(v) {
            if (!v) window.removeEventListener("click", this.bodyClick, false);
        }
    },
    methods: {
        getAccountBalance() {
            getAccountBalance().then(res => {
                if (res.code == 0) {
                    this.$store.dispatch("UPDATA_ACCOUNT_INFO", res.data);
                }
            });
        },
        blurEvent() {
            window.addEventListener("click", this.bodyClick);
        },
        bodyClick(e) {
            if (e.target.innerHTML != "确认") {
                this.editNickName = false;
            }
        },
        changeNickname() {
            this.editNickName = true;
            this.nickname = this.userInfo.nickname;
            setTimeout(() => {
                document.getElementById("ncInput").focus();
            }, 200);
        },
        confirmChangeNickname() {
            // 确认修改昵称
            updateNickname({
                nickname: this.nickname
            }).then(res => {
                if (res.code == 0) {
                    this.editNickName = false;
                    let uInfo = this.userInfo;
                    uInfo.nickname = this.nickname;
                    this.$store.dispatch("UPDATA_USER_INFO", uInfo);
                }
            });
        },
        toLogout() {
            // 退出登录
            this.$store.dispatch("UPDATA_USER_INFO", null);
            this.$router.push({ path: "/" });
        },
        handleRule() {
            getUserLevel().then(res => {
                this.html = `<p>从初级分销商到中级分销商，你只需要分享课程完成<span style="color:#ff4136">${
                    res.data[1].min_total_orders
                }单</span>，即可升级为<span style="color:#ff4136">中级分销商</span>。${
                    res.data[1].describe ? "且获得" : ""
                }<span style="color:#ff4136">${
                    res.data[1].describe ? res.data[1].describe : ""
                }</span><br/>从中级级分销商到高级级分销商，你只需要分享课程完成<span style="color:#ff4136">${res
                    .data[2].min_total_orders -
                    res.data[1]
                        .min_total_orders}单</span>，即可升级为<span style="color:#ff4136">高级分销商</span>。${
                    res.data[2].describe ? "且获得" : ""
                }<span style="color:#ff4136">${
                    res.data[2].describe ? res.data[2].describe : ""
                }</span></p>`;

                this.ruleShow = true;
            });
        },

        handleAvatarSuccess(res, file) {
            let avatar = res.data;
            updateAvatar({
                avatar: res.data.split(".com")[1]
            }).then(res => {
                if (res.code == 0) {
                    let uInfo = this.userInfo;
                    uInfo.avatar = avatar;
                    this.$store.dispatch("UPDATA_USER_INFO", uInfo);
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) this.$message.error("上传头像图片大小不能超过 2MB!");
            return isLt2M;
        }
    }
};
</script>

<style lang="less">
.personal-page {
    .content {
        width: 1200px;
        margin: 30px auto;
        .logo {
            width: 180px;
            height: 38px;
            margin-bottom: 30px;
            img {
                display: inline-block;
                width: 100%;
                height: 100%;
            }
        }
        .account-center {
            box-sizing: border-box;
            width: 330px;
            background: #f7f8f9;
            padding: 20px 40px;
            margin-bottom: 30px;
            .personal-info {
                display: flex;
                // margin-bottom: 22px;
                .user-avatar {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-right: 10px;
                    position: relative;
                }
                .avatar-uploader .el-upload {
                    border: 1px dashed transparent;
                    border-radius: 50%;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                }
                .avatar-uploader .el-upload:hover {
                    border-color: #000;
                }
                .avatar-uploader .el-upload:hover .mask {
                    display: block;
                }
                .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                }
                .avatar-uploader .mask {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.4);
                    color: #fff;
                    line-height: 90px;
                    font-size: 12px;
                    display: none;
                }
                .avatar {
                    width: 60px;
                    height: 60px;
                    display: block;
                    border-radius: 50%;
                }
                .user-info {
                    font-size: 0;
                    padding: 5px 0;
                    .p {
                        color: #333;
                        line-height: 22px;
                        margin-bottom: 10px;
                        font-size: 13px;
                        .user-name {
                            display: inline-block;
                            max-width: 125px;
                            overflow: hidden;
                            white-space: nowrap;
                            line-height: 22px;
                            text-overflow: ellipsis;
                            margin-right: 10px;
                            font-size: 16px;
                            vertical-align: middle;
                        }
                        .edit {
                            display: inline-block;
                            color: #2a67ff;
                            vertical-align: middle;
                            line-height: 22px;
                            img {
                                width: 12px;
                                height: 12px;
                                margin-top: 7px;
                                margin-right: 3px;
                            }
                        }
                        .el-input {
                            width: 130px;
                            margin-right: 5px;
                            .el-input__inner {
                                height: 22px;
                                line-height: 22px;
                                border-radius: 0;
                                padding: 0 8px;
                            }
                        }
                        .el-button.primary-btn {
                            width: 40px;
                            height: 22px;
                            line-height: 22px;
                            border-radius: 2px;
                        }
                    }
                    .user-mobile {
                        color: #666;
                        font-size: 14px;
                    }
                    .level {
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;
                        img {
                            height: 34px;
                            width: 98px;
                            display: block;
                            margin-right: 10px;
                        }
                        p {
                            text-decoration: underline;
                            font-size: 13px;
                            color: #ff3b30;
                            cursor: pointer;
                        }
                    }
                }
            }
            .logout {
                display: block;
                width: 76px;
                height: 26px;
                background: #fff;
                border-radius: 5px;
                border: 1px solid #fb3e31;
                margin: 0 auto;
                padding: 0;
                color: #fb3e31;
                font-weight: normal;
                font-size: 14px;
            }
        }
        .p-tabs {
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__nav-wrap::after {
                height: 3px;
                background-color: #2a67ff;
            }
            .el-tabs__nav {
                margin-bottom: 5px;
            }
            .el-tabs__item {
                width: 140px;
                height: 40px;
                line-height: 40px;
                background: #f1f1f1;
                color: #2a67ff;
                font-weight: normal;
                text-align: center;
                margin-right: 5px;
                padding: 0;
            }
            .el-tabs__item.is-active {
                background: #2a67ff;
                color: #fff;
            }
            .el-tabs__content {
                padding: 25px 0 0;
            }
        }
        .el-tab-pane {
            .statistics {
                margin-bottom: 30px;
                .box {
                    display: inline-block;
                    box-sizing: border-box;
                    min-width: 180px;
                    height: 100px;
                    border: 1px solid #ced1e9;
                    border-radius: 10px;
                    padding: 20px;
                    margin-right: 20px;
                    .ttl {
                        font-size: 13px;
                        color: #666;
                        margin-bottom: 10px;
                        .withdraw {
                            width: 37px;
                            height: 21px;
                            line-height: 22px;
                            font-size: 12px;
                            border-radius: 0;
                            margin-left: 19px;
                        }
                        img.q {
                            display: inline-block;
                            margin-left: 5px;
                            margin-top: 4px;
                        }
                    }
                    .num {
                        font-size: 14px;
                        color: #333;
                        .color {
                            font-family: "DINAlternateBold";
                            font-size: 28px;
                            color: #2a67ff;
                            line-height: 32px;
                        }
                        .color.mr {
                            margin-right: 8px;
                        }
                        .color.ml {
                            margin-left: 8px;
                        }
                        .color.y {
                            color: #fd890e;
                        }
                        .color.r {
                            color: #fb3e31;
                        }
                    }
                }
            }
            .el-table {
                border: 1px solid #f1f1f1;
                border-bottom: none;
                margin-bottom: 30px;
                color: #666;
                thead {
                    color: #333;
                    th {
                        padding: 0;
                        background-color: #f1f1f1;
                        > .cell {
                            line-height: 48px;
                        }
                    }
                    th.is-leaf {
                        border-bottom: none;
                    }
                }
                td {
                    padding: 0;
                    .cell {
                        line-height: 48px;
                    }
                }
                .opr {
                    button {
                        padding: 0;
                        .delete {
                            width: 18px;
                            height: 20px;
                        }
                    }
                }
            }
            .el-table__empty-block {
                border: 1px solid #f1f1f1;
            }
            .el-table::before {
                display: none;
            }
        }
    }
}
</style>
