<template lang="pug">
  el-dialog.withdraw-dialog(
    :visible='dialog'
    width='700px'
    title="提现"
    center
    :before-close="handleClose")
    .top-forms
      el-form.withdraw-form(
        ref="withdrawForm"
        :inline='true'
        :rules="rules" 
        :model='withdrawForm'
        label-width="80px")
        el-form-item(label='提现金额:' prop="withdrawal_amount")
          el-input(v-model='withdrawForm.withdrawal_amount')
            <i slot="suffix">元</i>
        el-form-item(label='真实姓名:' prop="name")
          el-input(v-model='withdrawForm.name')
        el-form-item(label='提现方式:')
          el-input.readonly-input(v-model='type' readonly)
        el-form-item(label='开户行:' prop="bank_name")
          el-select(v-model='withdrawForm.bank_name')
            el-option( v-for="(item,key) in banks" :key="key" :label='item' :value='item')
        el-form-item(label='银行账户:' prop="bank_account")
          el-input(v-model='withdrawForm.bank_account')
        el-form-item(label='验证码:' prop="code")
          el-input.code-input(v-model='withdrawForm.code')
          el-button.getcode-btn.primary-btn(:disabled="!canGetCode" @click='getCode') 获取验证码
        el-button.primary-btn.to-withdraw(@click='toWithDraw') 立即提现
    .bottom-tips
      .sub-title 提现须知
      .tips-content(v-html="wdTips")
</template>

<script>
import { getCode } from "@/api/login";
import { getInformation } from "@/api/index";
import {
    getWithdrawableBank,
    getLastWithdrawInfo,
    withdrawal
} from "@/api/personal.js";
export default {
    props: ["dialog", "maxMoney"],
    data() {
        const validateMoney = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入提现金额"));
            } else if (this.checkMoney(value)) {
                callback(new Error("请输入正确金额"));
            } else if (this.checkIsMax(value)) {
                callback(new Error("*您的可提现金额不足，请重新输入"));
            } else if (this.checkIsMin(value)) {
                callback(new Error("请输入正确金额"));
            } else {
                callback();
            }
        };
        const validateCode = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入验证码"));
            } else if (this.checkNumber(value)) {
                callback(new Error("验证码为6位数字"));
            } else {
                callback();
            }
        };
        return {
            withdrawForm: {
                withdrawal_amount: "",
                name: "",
                bank_name: "",
                bank_account: "",
                code: ""
            },
            type: "银联",
            rules: {
                withdrawal_amount: [
                    { validator: validateMoney, trigger: "blur" }
                ],
                code: [{ validator: validateCode, trigger: "blur" }],
                name: [
                    {
                        required: true,
                        message: "请输入真实姓名",
                        trigger: "blur"
                    },
                    {
                        min: 1,
                        max: 10,
                        message: "长度在 1 到 10 个字符之间",
                        trigger: "blur"
                    }
                ],
                bank_account: [
                    {
                        required: true,
                        message: "请输入银行账户",
                        trigger: "blur"
                    }
                ]
            },
            banks: [],
            canGetCode: true,
            codeTimer: null,
            wdTips: ""
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo.userInfo;
        }
    },
    watch: {
        dialog(v) {
            if (v) {
                this.withdrawForm.withdrawal_amount = this.maxMoney;
                // 获取银行账户信息
                this.getAccountBank();
                this.getLastWithdrawInfo();
                this.getInformation();
            } else {
                this.resetForm();
            }
        }
    },
    methods: {
        resetForm() {
            this.withdrawForm = {
                withdrawal_amount: "",
                name: "",
                bank_name: "",
                bank_account: "",
                code: ""
            };
        },
        checkIsMax(v) {
            return Number(v * 100) > Number(this.maxMoney * 100);
        },
        checkIsMin(v) {
            return Number(v * 100) == 0;
        },
        handleClose() {
            this.$emit("close");
        },
        getLastWithdrawInfo() {
            getLastWithdrawInfo().then(res => {
                if (res.code == 0) {
                    this.withdrawForm.name = res.data.name;
                    this.withdrawForm.bank_name = res.data.bank_name;
                    this.withdrawForm.bank_account = res.data.bank_account;
                }
            });
        },
        getAccountBank() {
            getWithdrawableBank().then(res => {
                if (res.code == 0) {
                    this.banks = res.data;
                }
            });
        },
        getCode() {
            getCode({
                mobile: this.userInfo.mobile,
                type: 4
            }).then(res => {
                if (res.code == 0) {
                    this.successMes(res.msg);
                    this.startTimer();
                }
            });
        },
        startTimer() {
            this.canGetCode = false;
            // 定时器
            var s = 60;
            if (this.codeTimer) {
                clearInterval(this.codeTimer);
            }
            this.codeTimer = setInterval(() => {
                s--;
                if (s == 0) {
                    clearInterval(this.codeTimer);
                    this.canGetCode = true;
                }
            }, 1000);
        },
        getInformation() {
            getInformation("3", {
                params: {
                    flag: "row"
                }
            }).then(res => {
                if (res.code == 0) {
                    this.wdTips = res.data.content;
                }
            });
        },
        toWithDraw() {
            this.$refs["withdrawForm"].validate(valid => {
                if (valid) {
                    //去提现
                    withdrawal({
                        ...this.withdrawForm
                    }).then(res => {
                        if (res.code == 0) {
                            this.$emit("close");
                            this.$emit(
                                "openWdSuccess",
                                this.withdrawForm.withdrawal_amount
                            );
                        }
                    });
                } else {
                    this.errorMes("校验失败");
                }
            });
        }
    }
};
</script>

<style lang="less">
.withdraw-dialog {
    .el-dialog__header {
        height: 60px;
        line-height: 60px;
        background: linear-gradient(135deg, #517bfc, #517bfc);
        padding: 0 30px;
        .el-dialog__headerbtn {
            border-color: transparent #7c9cfd transparent transparent;
        }
        .el-dialog__title {
            font-size: 18px;
            font-weight: 500 !important;
        }
    }
    .el-dialog__body {
        height: 50vh;
        overflow-y: scroll;
        padding: 40px 0 0;
        font-size: 14px;
        color: #666;
        margin-bottom: 40px;
        background: #fff;
        .top-forms {
            padding: 0 40px 35px;
            .withdraw-form {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .el-form-item {
                    margin-bottom: 30px;
                    margin-right: 0 !important;
                }
                .is-required {
                    .el-form-item__label::before {
                        display: none;
                    }
                }
                .el-input {
                    width: 215px;
                }
                .readonly-input input {
                    border: none;
                }
                .code-input {
                    width: 120px;
                }
                .getcode-btn {
                    width: 90px;
                    height: 40px;
                    line-height: 40px;
                    margin-left: 5px;
                    background: #fff;
                    border: 1px solid #2a67ff;
                    color: #666;
                }
                .el-button.is-disabled {
                    background: #ccc !important;
                    border: 1px solid #ccc;
                    color: #fff;
                }
                .to-withdraw {
                    display: block;
                    width: 180px;
                    height: 40px;
                    line-height: 40px;
                    background: #409eff;
                    margin: 0 auto;
                }
            }
        }
        .bottom-tips {
            padding: 20px 40px 0;
            border-top: 1px solid #d7dae2;
            .sub-title {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding-left: 13px;
                font-size: 16px;
                color: #333;
                line-height: 22px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            .sub-title:before {
                content: "";
                width: 3px;
                height: 17px;
                position: absolute;
                top: 4px;
                left: 0;
                background: #2a67ff;
            }
            .tips-content {
                line-height: 21px;
                p {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>
