<template lang="pug">
  .personal-orders
    .statistics
      .box
        .ttl 分销商品
        .num
          span.color.mr {{accountInfo.distributor_product_total}}
          | 件
      .box
        .ttl 总销售额
        .num
          | ¥
          span.color.ml {{accountInfo.total_sales}}
      .box
        .ttl 总佣金
        .num
          | ¥
          span.color.ml {{accountInfo.total_commission}}
    el-form(ref='ordersForm', :model='ordersForm', label-width='70px' :inline="true")
      el-form-item(label='下单时间')
        el-date-picker(
          v-model='timeInterval'
          type='daterange'
          range-separator='-'
          start-placeholder='开始日期'
          end-placeholder='结束日期'
          @change="changeTimeStage")
      el-form-item.ml-2(label='订单状态')
        el-select(v-model='ordersForm.order_status' @change="getOrdersData")
          el-option( v-for="(item,key) in statusList" :key="key" :label='item' :value='key')
    .orders-data
      el-table.orders-table(
        :data='ordersList'
        style='width: 100%')
        el-table-column(prop='goods_name' label='商品名称' align="left" header-align="center" show-overflow-tooltip)
        el-table-column(prop="order_sn" label='订单编号' align="center" width='200')
        el-table-column(prop='nickname' label='买家名称' show-overflow-tooltip  align="center" width='130')
        el-table-column(prop='mobile' label='买家电话' align="center" width='110')
        el-table-column(prop='create_time' label='下单时间' align="center" width='150')
        el-table-column(label='订单状态'  align="center" width='80')
          template(slot-scope='scope')
            .status(:class="{'red':scope.row.order_status == '待支付'}") {{scope.row.order_status}}
        el-table-column(label='提现状态'  align="center" width='80')
          template(slot-scope='scope')
            .status(:class="{'yellow':scope.row.settlement_status == '待结算'}") {{scope.row.settlement_status}}
        el-table-column(prop='pay_price' label='商品单价（元）' align="center" width='120')
        el-table-column(prop='commission_price' label='获得佣金（元）' align="center"  class-name="earnings" width='120')
      Pagination(
        :currentPage.sync="pagination.page"
        :pageSize="pagination.perpage"
        :total="pagination.count"
        :sumPage="pagination.page_total")
</template>
<script>
import {getOrderStatus} from '@/api/product.js'
import {getDistributorOrderList} from '@/api/personal.js'
import Pagination from '@/components/pagination'
export default {
  props:['active'],
  components:{Pagination},
  data() {
    return {
      ordersForm:{
        order_status:'',
        start_date:'',
        end_date:''
      },
      timeInterval:'',
      statusList:[],
      ordersList:[],
      pagination:{
        page:1,
        perpage:1,
        count:1,
        page_total:1
      }
    }
  },
  watch:{
    active(val){
      if(val == 'orders'){
        this.getOrdersData()
      }
    },
    'pagination.page'(v){
      this.getOrdersData()
    }
  },
  computed:{
    accountInfo(){
      return this.$store.state.userInfo.accountInfo
    }
  },
  methods:{
    changeTimeStage(v){
      if(v){
        this.ordersForm.start_date = this.formatTime(v[0])
        this.ordersForm.end_date = this.formatTime(v[1])
      }else{
        this.ordersForm.start_date = '';
        this.ordersForm.end_date = ''
      }
      
      console.log(v);
      this.getOrdersData()
    },
    formatTime(v){
      const date = new Date(v);
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = date.getDate() + ' ';
      const dateString = Y + M + D ;
      return dateString
    },
    getOrderStatus(){
      getOrderStatus().then(res => {
        if (res.code == 0) {
          this.statusList = res.data
        }
      })
    },
    getOrdersData(){
      // 获取分销商品数据
      const data = {
        ...this.ordersForm,
        page:this.pagination.page
      }
      getDistributorOrderList({
        ...data
      }).then(res => {
        if(res.code == 0){
          this.ordersList = res.data.list
          this.pagination = res.data.paginate
        }
      })
    },
  },
  mounted(){
    this.getOrderStatus()
  }  
}
</script>

<style lang="less">
.personal-orders{
  .ml-2{
    margin-left: 20px;
  }
  .el-table{
    .status,
    td.earnings{
      color: #2A67FF;
    }
    .status.red{
      color: #FB3E31;
    }
    .status.yellow{
      color: #FD890E;
    }
  }
}
</style>
