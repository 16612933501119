<template lang="pug">
  .personal-goods
    .statistics
      .box
        .ttl 分销商品
        .num
          span.color.mr {{accountInfo.distributor_product_total}}
          | 件
      .box
        .ttl 总销售额
        .num
          | ¥
          span.color.ml {{accountInfo.total_sales}}
    el-form(ref='goodsForm', :model='goodsForm', label-width='70px' :inline="true")
      el-form-item(label='课程分类')
        el-select(v-model='goodsForm.product_type', placeholder='请选择课程分类' @change="getGoodsData")
          el-option( v-for="item in classics" :key="item.id" :label='item.name' :value='item.id')
      el-form-item(label='状态')
        el-select(v-model='goodsForm.product_status' @change="getGoodsData")
          el-option( v-for="(item,key) in statusList" :key="key" :label='item' :value='key')
    .goods-data
      el-table.goods-table(
        :data='goodsList'
        style='width: 100%')
        el-table-column(prop='goods_name' label='商品名称' align="left" header-align="center"  show-overflow-tooltip width='310')
          template(slot-scope='scope')
            .goods-name.c-pointer(v-if="scope.row.product_status == 1" @click="goDetail(scope.row.product_id)") {{scope.row.goods_name}}
            span(v-else) {{scope.row.goods_name}}
        el-table-column(prop="type_name" label='课程分类' align="center" width='150')
        el-table-column(prop='goods_price' label='商品售价（元）' align="center" width='150')
        el-table-column(prop='commission_price' label='收益（元）' align="center"  width='150')
        el-table-column(prop='order_count' label='购买人数' align="center" width='150')
        el-table-column(label='商品状态'  align="center")
          template(slot-scope='scope')
            .status(:class="{'red':scope.row.product_status == 0}") {{scope.row.product_status == 0 ? '已下架':'已上架'}}
        el-table-column(label='操作' align="center" class-name="opr" width='100')
          el-button(slot-scope='scope' type="text" @click="confirmDetele(scope.row.id)")
            svg-icon.delete(icon-class="delete")
      Pagination(
        :currentPage.sync="pagination.page"
        :pageSize="pagination.perpage"
        :total="pagination.count"
        :sumPage="pagination.page_total")
</template>
<script>
import {getProductType, getProductStatus} from '@/api/product.js'
import {getDistributorProductList,deleteProduct} from '@/api/personal.js'
import Pagination from '@/components/pagination'
export default {
  props:['active'],
  components:{Pagination},
  data() {
    return {
      goodsForm:{
        product_type:'',
        product_status:''
      },
      classics:[],
      statusList:[],
      goodsList:[],
      pagination:{
        page:1,
        perpage:1,
        count:1,
        page_total:1
      }
    }
  },
  watch:{
    active(val){
      if(val == 'goods'){
        this.getGoodsData()
      }
    },
    'pagination.page'(v){
      this.getGoodsData()
    }
  },
  computed:{
    accountInfo(){
      return this.$store.state.userInfo.accountInfo
    }
  },
  methods:{
    getGoodsData(){
      // 获取分销商品数据
      const data = {
        ...this.goodsForm,
        page:this.pagination.page
      }
      getDistributorProductList({
        ...data
      }).then(res => {
        if(res.code == 0){
          this.goodsList = res.data.list
          this.pagination = res.data.paginate
        }
      })
    },
    getProductType(){
      getProductType().then(res => {
        if (res.code == 0) {
          this.classics = res.data
        }
      })
    },
    getProductStatus(){
      getProductStatus().then(res => {
        if (res.code == 0) {
          this.statusList = res.data
        }
      })
    },
    confirmDetele(id){
      this.$confirm("<i class='el-icon-warning'></i>您确定要取消该门课程的代理权吗?", '提示', {
        confirmButtonText: '取消',
        cancelButtonText: '确定',
        type: 'info',
        center: true,
        dangerouslyUseHTMLString:true,
        distinguishCancelAndClose: true
      }).then(() => {
        // 取消
      }).catch((action) => {
        // 确定
        if(action === 'cancel'){
          deleteProduct(id).then(res => {
            if (res.code == 0) {
              this.successMes('删除成功')
              this.getGoodsData()
            }
          })
        }
      })
    },
    goDetail(product_id){
      this.$router.push({name: "courseDetail",query: {id: product_id}})
    }
  },
  mounted(){
    this.getProductType()
    this.getProductStatus()
  }
}
</script>

<style lang="less">
.personal-goods{
  .el-form{
    .el-form-item__label{
      font-size: 14px;
    }
    .el-select{
      width: 160px;
    }
  }
  .el-table{
    .goods-name{
      color: #2A67FF;
      text-decoration: underline;
    }
    .status.red{
      color: #FB3E31;
    }
  }
}
.el-message-box{
  border: none;
  .el-message-box__header{
    height: 50px;
    background:linear-gradient(135deg,rgba(81,123,252,.5) 0%,rgba(81,123,252,.5) 100%);
    .el-message-box__headerbtn{
      border-color:transparent #7C9CFD transparent transparent;
    }
    .el-message-box__title{
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 500 !important;
      .el-message-box__status{
        display: none;
      }
    }
  }
  .el-message-box__content{
    padding: 45px 50px;
    font-size: 18px;
    color: #333;
    .el-message-box__message{
      i{
        color: #FD890E;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
  .el-message-box__btns{
    button{
      width: 109px;
      height: 40px;
      line-height: 40px;
      padding: 0;
      font-size: 14px;
      color: #2A67FF;
      border-color: #2A67FF;
      font-weight: normal;
    }
    button.el-button--primary{
      background-color: #2A67FF;
      color: #fff;
    }
  }
}
</style>
