<template lang="pug">
  .pagination-view
    el-pagination(
      :current-page.sync='currPage'
      :page-size='pageSize'
      :total='total'
      layout='prev, pager, next'
      @current-change='handleCurrentChange')
    span.sum-page 
      | 共
      i {{sumPage}}
      | 页
    el-pagination(
      :current-page.sync='currPage'
      :page-size='pageSize'
      :total='total'
      layout='jumper'
      @current-change='handleCurrentChange')
    span.total 
      | 共
      i {{total}}
      | 条
</template>

<script>
export default {
  name: "pagination",
  props:{
    currentPage:{
      type:Number,
      default:1
    },
    pageSize:{
      type:Number
    },
    total:{
      type:Number
    },
    sumPage:{
      type:Number
    },
    layout:{
      type:String,
      default:'prev, pager, next, jumper, total'
    }
  },
  data() {
    return {
      currPage:1
    }
  },
  methods:{
    handleCurrentChange(){
      this.$emit('update:currentPage',this.currPage)
    }
  },
  mounted(){
    this.currPage = this.currentPage
  }
}
</script>
<style lang="less">
.pagination-view{
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 28px;
  .el-pagination{
    display: inline-block;
    padding: 0;
    &>button{
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin: 0 5px;
      .el-icon{
        font-size: 16px;
        color: #666;
      }
    }
    &>button:disabled{
      .el-icon{
        color: #C0C4CC;
      }
    }
    .el-pager{
      li{
        box-sizing: border-box;
        width: 28px;
        height: 28px;
        line-height: 28px;
        padding: 0;
        margin: 0 5px;
        font-size: 16px;
        color: #333;
        font-weight: normal;
      }
      li.active{
        color: #1989FA;
      }
    }
    .el-pagination__jump{
      height: 28px;
      line-height: 28px;
      font-size: 16px;
      color: #333;
      margin-left: 34px;
      margin-right: 30px;
      .el-pagination__editor{
        height: 28px;
        line-height: 28px;
        margin: 0 10px;
        .el-input__inner{
          height: 26px;
          border-radius: 4px;
          border: 1px solid #D1D1D1;
          font-size: 16px;
        }
      }
    }
    .el-pagination__total{
      font-size: 16px;
      color: #333;
      line-height: 28px;
    }
  }
  .sum-page{
    margin-left: 10px;
    i{
      margin: 0 3px;
    }
  }
  .total{
    i{
      margin: 0 3px;
    }
  }
}
</style>
