<template lang="pug">
  el-dialog.withdraw-success-dialog(
    :visible='dialog'
    width='460px'
    :show-close="false"
    :before-close="handleClose")
    img.ic_right(src="@/assets/images/success.png")
    .ttl 申请提现成功
    .tips {{userInfo ? userInfo.nickname:'尊敬的用户'}} 您好，您已于小鸦分销平台提现 
      span.money {{wdMoney}}
      | 元，该笔钱将于两小时之内转账到您的银行账户，请注意查收！
    el-button.primary-btn(@click='handleClose') 我知道了
</template>

<script>
export default {
    props: ["dialog", "wdMoney"],
    data() {
        return {};
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo.userInfo;
        }
    },
    methods: {
        handleClose() {
            this.$emit("close");
        }
    }
};
</script>

<style lang="less">
.withdraw-success-dialog {
    .el-dialog__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #333;
        font-size: 14px;
        padding: 40px;
        background: #fff;
        .ic_right {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-bottom: 10px;
        }
        .ttl {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .tips {
            margin-bottom: 40px;
            .money {
                color: #2a67ff;
            }
        }
        .primary-btn {
            display: block;
            width: 180px;
            height: 40px;
            line-height: 40px;
            background: #409eff;
            margin: 0 auto;
        }
    }
}
</style>
